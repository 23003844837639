<app-navbar></app-navbar>

<div class="transition-none">
    <section class="title-hero-bg" style="background-image: url(assets/images/title-bg/title-bg-3.jpg);">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="page-title text-center white-color">
                        <h1 class="raleway-font font-300">Countdowns</h1>
                        <div class="breadcrumb mt-20">
                            <!-- Breadcrumb Start -->
                            <ul>
                                <li><a routerLink="/home-one">Home</a></li>
                                <li>Countdowns</li>
                            </ul>
                            <!-- Breadcrumb End -->
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>

<section class="white-bg">
    <div class="container">
        <div class="row">
            <div class="col-sm-8 section-heading">
                <h4 class="text-uppercase mt-0">Countdown Style 01</h4>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-12 text-center">
                <div class="countdown-container mt-0 mb-0">
                    <ul class="countdown">
                        <li>
                            <span class="days default-color font-30px font-400">{{ countdown.days }}</span>
                            <p class="dark-color text-uppercase">days</p>
                        </li>
                        <li>
                            <span class="hours default-color font-30px font-400">{{ countdown.hours }}</span>
                            <p class="dark-color text-uppercase">hours</p>
                        </li>
                        <li>
                            <span class="minutes default-color font-30px font-400">{{ countdown.minutes }}</span>
                            <p class="dark-color text-uppercase">minutes</p>
                        </li>
                        <li>
                            <span class="seconds default-color font-30px font-400">{{ countdown.seconds }}</span>
                            <p class="dark-color text-uppercase">seconds</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="gradient-bg-six">
    <div class="container">
        <div class="row">
            <div class="col-sm-8 section-heading white-color">
                <h4 class="text-uppercase mt-0">Countdown Style 02</h4>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-12 text-center">
                <div class="countdown-container mt-0 mb-0">
                    <ul class="countdown">
                        <li class="white-color">
                            <span class="days font-30px font-400">{{countdown.days}}</span>
                            <p class="text-uppercase">days</p>
                        </li>
                        <li class="white-color">
                            <span class="hours font-30px font-400">{{countdown.hours}}</span>
                            <p class="text-uppercase">hours </p>
                        </li>
                        <li class="white-color">
                            <span class="minutes font-30px font-400">{{countdown.minutes}}</span>
                            <p class="text-uppercase">minutes</p>
                        </li>
                        <li class="white-color">
                            <span class="seconds font-30px font-400">{{countdown.seconds}}</span>
                            <p class="text-uppercase">seconds</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="parallax-bg fixed-bg" style="background: url('assets/images/background/parallax-bg-2.jpg');">
    <div class="parallax-overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-sm-8 section-heading white-color">
                <h4 class="text-uppercase mt-0">Countdown Style 03</h4>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-12 text-center">
                <div class="countdown-container mt-0 mb-0">
                    <ul class="countdown">
                        <li class="white-color">
                            <span class="days font-80px font-400 gradient-color">{{countdown.days}}</span>
                            <p class="text-uppercase font-30px">days</p>
                        </li>
                        <li class="white-color">
                            <span class="hours font-80px font-400 gradient-color">{{countdown.hours}}</span>
                            <p class="text-uppercase font-30px">hours </p>
                        </li>
                        <li class="white-color">
                            <span class="minutes font-80px font-400 gradient-color">{{countdown.minutes}}</span>
                            <p class="text-uppercase font-30px">minutes</p>
                        </li>
                        <li class="white-color">
                            <span class="seconds font-80px font-400 gradient-color">{{countdown.seconds}}</span>
                            <p class="text-uppercase font-30px">seconds</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="white-bg">
    <div class="container">
        <div class="row">
            <div class="col-sm-8 section-heading">
                <h4 class="text-uppercase mt-0">Countdown Style 04</h4>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-12 text-center">
                <div class="countdown-container mt-0 mb-0">
                    <ul class="countdown">
                        <li>
                            <span class="days dark-color font-30px font-400">{{ countdown.days }}</span>
                            <p class="dark-color text-uppercase">days</p>
                        </li>
                        <li>
                            <span class="hours dark-color font-30px font-400">{{ countdown.hours }}</span>
                            <p class="dark-color text-uppercase">hours</p>
                        </li>
                        <li>
                            <span class="minutes dark-color font-30px font-400">{{ countdown.minutes }}</span>
                            <p class="dark-color text-uppercase">minutes</p>
                        </li>
                        <li>
                            <span class="seconds dark-color font-30px font-400">{{ countdown.seconds }}</span>
                            <p class="dark-color text-uppercase">seconds</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>